import Dashboard from '@/models/Dashboard';

export default class DashboardRepository {
    public static getAll() {
        return Dashboard.all();
    }

    public static getById(id: string) {
        return Dashboard.query()
            .whereId(id)
            .withAll()
            .first();
    }
}
