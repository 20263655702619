var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dashboards-wrapper"},[_c('div',{staticClass:"dasboards__top-bar"},[_c('span',{staticClass:"dashboards__title"},[_vm._v("Dashboards")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.navigateToCurrentUsersDashboard()}}},[_vm._v(_vm._s(_vm.$t('My dashboard')))])],1),_c('div',{staticClass:"dashboards-content"},[_c('div',{staticClass:"search-bar"},[_c('a-input-search',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Search dashboards..."},on:{"keyup":(e) => _vm.onSearch(e.target.value)}})],1),_c('div',{staticClass:"dashboards-table-wrapper"},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.filteredDashboards || _vm.dashboards,"rowClassName":() => 'dashboards__table-row',"pagination":false,"rowKey":(record) => record.id,"customRow":(record) => {
                        return {
                            on: {
                                click: () => _vm.navigateToDashboard(record.id), // click row
                            },
                        };
                    }}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }